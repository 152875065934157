import { addInterceptor } from "@reditor/api-gateway"
import { navigate } from "gatsby"
import React, { useEffect, useState } from "react"
import { useCurrentUser } from "services/auth"
import { AuthenticationContext } from "./authentication-context"

/**
 * Configures the Axios request interceptor to include an Authorization header.
 * @param {string} accessToken 
 */
function configureAxiosAuthHeader(accessToken) {
  addInterceptor((config) => {
    config.headers.Authorization = `Bearer ${accessToken}`
    return config
  })
}

/**
 * @type {React.FC<React.PropsWithChildren>}
 */
const AuthenticationProvider = ({ children }) => {
  const { data, isError } = useCurrentUser()
  const [authIncomplete, setAuthIncomplete] = useState(false)
  const [authHeaderConfigured, setAuthHeaderConfigured] = useState(false)

  const accessToken = data?.session.tokens?.accessToken.toString()
  const idToken = data?.session.tokens?.idToken?.toString()
  const idTokenPayload = data?.session.tokens?.idToken?.payload
  const username = idTokenPayload?.["name"]?.toString()
  const email = idTokenPayload?.["email"]?.toString()
  const attributes = data?.attributes ?? {}

  useEffect(() => {
    if (!isError) return

    console.debug("Found unauthenticated user. Redirecting to login page...")
    navigate("/auth/login")
  }, [isError])

  useEffect(() => {
    if (!authIncomplete) return

    console.debug(
      "Found incomplete authentication data. Redirecting to login page...",
    )
    navigate("/auth/login")
  }, [authIncomplete])

  useEffect(() => {
    if (!accessToken) return

    configureAxiosAuthHeader(accessToken)
    setAuthHeaderConfigured(true)
    console.debug(
      "Successfully added Authorization header to Axios request interceptor with the access token.",
    )
  }, [accessToken])

  if (!data || !authHeaderConfigured) {
    return <></>
  }

  if (!accessToken || !idToken || !username || !email) {
    setAuthIncomplete(true)
    return <></>
  }

  const user = { username, email, attributes }
  const session = { accessToken, idToken }

  return (
    <AuthenticationContext.Provider value={{ user, session }}>
      {children}
    </AuthenticationContext.Provider>
  )
}

export default AuthenticationProvider
