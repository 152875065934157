import { graphql, useStaticQuery } from "gatsby"

/**
 * @typedef {Object} SiteMetadata
 * @property {string} [title] - The title of the site.
 * @property {string} [siteUrl] - The base URL of the site.
 * @property {string} [siteEnv] - The environment of the site (e.g., development, production).
 */

/**
 * A hook that returns the site's metadata.
 * @returns {SiteMetadata} An object containing the site's metadata.
 */
const useSiteMetadata = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          siteUrl
          siteEnv
        }
      }
    }
  `)
  return data.site.siteMetadata
}

export default useSiteMetadata
