import React from "react"

/**
 * @typedef {Object} AuthUser
 * @property {string} username
 * @property {string} email
 * @property {Record<string, string | undefined>} attributes
 *
 * @typedef {Object} AuthSession
 * @property {string} accessToken

 * @property {string} idToken
 *
 * @typedef {Object} AuthenticationContextValue
 * @property {AuthUser} user
 * @property {AuthSession} session
 */

/** @type {React.Context<AuthenticationContextValue | null>} */
// @ts-ignore
export const AuthenticationContext = React.createContext(null)
