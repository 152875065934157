import { Amplify } from "aws-amplify"
import * as Auth from "aws-amplify/auth"

function getRedirectLogoutURL() {
  return process.env.GATSBY_AWS_OAUTH_LOGOUT_URL?.split(",").map(
    (path) => `${window.location.origin}${path}`,
  )
}
function getRedirectCallbackURL() {
  return process.env.GATSBY_AWS_OAUTH_REDIRECT_URL?.split(",").map(
    (path) => `${window.location.origin}${path}`,
  )
}

/**
 *
 * @returns {import('aws-amplify').ResourcesConfig} config
 */
export const awsConfig = () => {
  const userPoolId = process.env.GATSBY_AWS_USER_POOL_ID
  const userPoolClientId = process.env.GATSBY_AWS_USER_POOL_CLIENT_ID
  const domain = process.env.GATSBY_AWS_OATH_DOMAIN
  const redirectSignIn = getRedirectCallbackURL()
  const redirectSignOut = getRedirectLogoutURL()

  if (
    !userPoolId ||
    !userPoolClientId ||
    !domain ||
    !redirectSignIn ||
    !redirectSignOut
  ) {
    const message = `Missing any of the required environment variables: 
      GATSBY_AWS_USER_POOL_ID=[${userPoolId}], 
      GATSBY_AWS_USER_POOL_CLIENT_ID=[${userPoolClientId}], 
      GATSBY_AWS_OATH_DOMAIN=[${domain}]
      GATSBY_AWS_OAUTH_REDIRECT_URL=[${redirectSignIn}]
      GATSBY_AWS_OAUTH_LOGOUT_URL=[${redirectSignOut}]
    `
    throw new Error(message)
  }

  return {
    Auth: {
      Cognito: {
        // Amazon Cognito User Pool ID
        userPoolId,

        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolClientId,

        loginWith: {
          // OPTIONAL - Hosted UI configuration
          oauth: {
            domain,
            // scopes: ["email", "openid", "phone"],
            scopes: [],
            redirectSignIn,
            redirectSignOut,
            responseType: "code", // or 'token', note that REFRESH token will only be generated when the responseType is code
          },
        },
      },
    },
  }
}

export function initialize() {
  Amplify.configure(awsConfig())
}

/**
 * Auth.federatedSignIn has become signInWithRedirect in v6
 *
 * The details can be found here: https://docs.amplify.aws/gen1/javascript/build-a-backend/auth/auth-migration-guide/#authfederatedsignin
 * @returns
 */
export function openHostedLogin() {
  return Auth.signInWithRedirect()
}

/**
 * @typedef {Awaited<ReturnType<typeof Auth.fetchAuthSession>>} AuthSession
 *
 * @param {Auth.FetchAuthSessionOptions} [option]
 */
export function oauthToken(option) {
  return Auth.fetchAuthSession(option)
}

/**
 * @throws - {@link InitiateAuthException} - Thrown when the service fails to refresh the tokens.
 * @throws - {@link GetUserException} - Cognito service errors thrown when the service is not able to get the user.
 * @throws AuthTokenConfigException - Thrown when the token provider config is invalid.
 */
export async function getCurrentUser() {
  const user = await Auth.getCurrentUser()
  const attributes = await Auth.fetchUserAttributes()
  const session = await Auth.fetchAuthSession()
  return { user, attributes, session }
}

export function logout() {
  return Auth.signOut()
}
