import { flip, offset, shift, useFloating } from "@floating-ui/react-dom"
import React from "react"

import Avatar from "./Avatar"
import AvatarMenu from "./AvatarMenu"

const AvatarButtonLayout = React.forwardRef(
  (
    /** @type {React.ButtonHTMLAttributes<HTMLButtonElement>} */
    { className, children, ...restProps },
    ref,
  ) => (
    <button ref={ref} className={className} {...restProps}>
      <Avatar>{children}</Avatar>
    </button>
  ),
)

/**
 * @type {React.FC<{
 *  username?: string
 *  email?: string
 *  className?: string
 * }>}
 */
const AvatarButtonContainer = ({ username, email, className = "" }) => {
  const [isMenuOpen, setMenuOpen] = React.useState(false)
  const { refs, floatingStyles } = useFloating({
    placement: "bottom-end",
    strategy: "absolute",
    middleware: [offset(2), flip(), shift()],
  })

  return (
    <>
      <AvatarButtonLayout
        className={className}
        ref={refs.setReference}
        onClick={() => setMenuOpen((open) => !open)}>
        {username?.charAt(0).toUpperCase()}
      </AvatarButtonLayout>
      {isMenuOpen ? (
        <AvatarMenu
          username={username}
          email={email}
          ref={refs.setFloating}
          style={{
            ...floatingStyles,
            width: "max-content",
          }}
        />
      ) : null}
    </>
  )
}

export default AvatarButtonContainer
