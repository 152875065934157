import { useI18next } from "gatsby-plugin-react-i18next"
import { getCurrentUser, oauthToken } from "lib/oauth"
import { useQuery } from "lib/react-query"
import { toast } from "lib/toast"
import { useEffect } from "react"

export function useAuthTokenFromRedirect() {
  const { navigate, t } = useI18next()

  useEffect(() => {
    oauthToken()
      .then(() => {
        navigate("/", { replace: true, state: undefined })
      })
      .catch(() => {
        toast(t("loginPage.authFailed"), "error")
        navigate("/auth/login", { replace: true })
      })
  }, [navigate, t])
}

/**
 * @typedef {Parameters<oauthToken>[0]} GetOAuthTokenParams
 * @typedef {import("@tanstack/react-query").UseQueryOptions<
 *   Awaited<ReturnType<typeof oauthToken>>,
 *   unknown,
 *   Awaited<ReturnType<typeof oauthToken>>
 * >} UseUserSessionOptions
 *
 * @param {GetOAuthTokenParams} params
 * @param {Omit<UseUserSessionOptions, 'queryKey' | 'queryFn'>} [options]
 *
 */
export const useUserSession = (params = {}, options = {}) => {
  return useQuery({
    ...options,
    queryKey: ["currentUserSession"],
    queryFn: () => oauthToken(params),
  })
}

/**
 * @typedef {import("@tanstack/react-query").UseQueryOptions<
 *   Awaited<ReturnType<typeof getCurrentUser>>,
 *   unknown,
 *   Awaited<ReturnType<typeof getCurrentUser>>
 * >} UseCurrentUserOptions
 *
 * @param {Omit<UseCurrentUserOptions, 'queryKey' | 'queryFn'>} [options]
 */
export const useCurrentUser = (options = {}) => {
  return useQuery({
    ...options,
    queryKey: ["currentUser"],
    queryFn: () => getCurrentUser(),
  })
}
