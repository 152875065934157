import { useContext } from "react"
import { isBrowser } from "utils"
import { AuthenticationContext } from "./authentication-context"

const useAuthentication = () => {
  const contextValue = useContext(AuthenticationContext)

  /** Fallback Context Value for build time */
  if (!isBrowser()) {
    return {
      user: { username: "", email: "", attributes: {} },
      session: { accessToken: "", idToken: "" },
    }
  }

  if (contextValue === null) {
    throw new Error(
      "useAuthentication must be used within a AuthenticationProvider",
    )
  }

  return contextValue
}

export default useAuthentication
