import React from "react"

import * as APIGateway from "@reditor/api-gateway"
import { setDefaultGateway } from "@reditor/core/gateways/data-request"

import PageLayoutProvider from "./src/providers/PageLayoutProvider"
import RootProvider from "./src/providers/RootProvider"

import { initialize } from "lib/oauth"
import "./src/styles/global.css"

/** @type {import('gatsby').GatsbyBrowser['wrapPageElement']} */
export const wrapPageElement = ({ element, props }, options) => {
  const isAuthRequired = Boolean(props.pageContext.isAuthRequired)
  return (
    <PageLayoutProvider isAuthRequired={isAuthRequired}>
      {element}
    </PageLayoutProvider>
  )
}

/** @type {import('gatsby').GatsbyBrowser['wrapRootElement']} */
export const wrapRootElement = ({ element }, options) => {
  return <RootProvider>{element}</RootProvider>
}

/** @type {import('gatsby').GatsbyBrowser["onClientEntry"]} */
export const onClientEntry = () => {
  console.log("client start")
  console.log("BUILD VERSION:", process.env.BUILD_VERSION)

  // Some initialization
  initialize()

  // setup the default api gateway
  setDefaultGateway(APIGateway)
  // or, set with other gateway
  // setDefaultGateway(StrapiAPIGateway)

  // if (process.env.NODE_ENV === 'development') {
  //   const worker = setupWorker(...APIGateway.mockHandlers)
  //   worker.start({
  //     onUnhandledRequest: 'bypass',
  //   })
  // }
}
