import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import * as React from "react"

import AuthenticationProvider from "features/auth/AuthenticationProvider"
import { AlertDialogContainer } from "../lib/alert-dialog"
import { ToastContainer } from "../lib/toast"
import { AlertDialog } from "../ui-kit"

/**
 * @param {{children: React.ReactElement, isAuthRequired: boolean }} param0
 */
export default function PageProvider({ children, isAuthRequired }) {
  if (isAuthRequired) {
    return (
      <AuthenticationProvider>
        {children}
        <ToastContainer />
        <AlertDialogContainer component={AlertDialog} />
        <ReactQueryDevtools initialIsOpen={false} />
      </AuthenticationProvider>
    )
  }
  return (
    <>
      {children}
      <ToastContainer />
      <AlertDialogContainer component={AlertDialog} />
      <ReactQueryDevtools initialIsOpen={false} />
    </>
  )
}
