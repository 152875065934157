import { Link, useI18next } from "gatsby-plugin-react-i18next"
import * as React from "react"

import useAuthentication from "features/auth/useAuthentication"
import useSiteMetadata from "lib/useSiteMetadata"
import AvatarButtonContainer from "./AvatarButton/AvatarButton"
import GiantLogo from "./shared/icons/GiantLogo"

/**
 * @type {React.FC<{
 *   hideLogo?: boolean
 *   hideLinks?: boolean
 * }>}
 */
const PageHeader = ({ hideLinks = false }) => {
  const { t } = useI18next()
  const { siteEnv } = useSiteMetadata()
  const { user } = useAuthentication()

  const links = Object.freeze([
    { label: t("all"), to: "/" },
    { label: `${t("distributorList")}`, to: "/distributors/" },
    { label: `${t("dealerList")}`, to: "/dealers/" },
    { label: `${t("storeList")}`, to: "/stores/" },
  ])

  return (
    <header className="z-header h-header sticky top-0 left-0 right-0 bg-white border-b border-b-grey-light">
      <nav className="flex flex-row h-full px-3">
        <ul className="flex flex-row justify-center items-center space-x-4 w-full">
          <li>
            <GiantLogo siteEnv={siteEnv} size="small" />
          </li>
          {hideLinks
            ? null
            : links.map((link) => {
                return (
                  <li key={link.label}>
                    <Link
                      to={link.to}
                      placeholder={link.label}
                      className="text-grey-70 text-base font-normal p-1 transition-shadow whitespace-nowrap"
                      activeClassName="!text-black font-semibold">
                      {link.label}
                    </Link>
                  </li>
                )
              })}
          <li className="flex-1"></li>
          <li>
            <AvatarButtonContainer
              username={user.username}
              email={user.email}
            />
          </li>
        </ul>
      </nav>
    </header>
  )
}

export default PageHeader
