import React from "react"

import { logout } from "lib/oauth"
import Avatar from "./Avatar"

const AvatarMenu = React.forwardRef(
  (
    /** @type {React.HTMLAttributes<HTMLDivElement> & {email?: string, username?: string}} */
    { className, username, email, ...restProps },
    ref,
  ) => {
    return (
      <div
        ref={ref}
        className={`
        rounded border border-grey-light bg-white 
        flex flex-col divide-y divide-grey-light
        ${className}
      `}
        {...restProps}>
        <div className="p-3 gap-3 flex flex-row items-center">
          <Avatar>{username?.charAt(0).toUpperCase()}</Avatar>
          <div>
            <div className="font-medium">{username}</div>
            <div className="font-medium text-grey-70">{email}</div>
          </div>
        </div>
        <button
          className="appearance-none px-3 py-2 text-left font-medium text-sm"
          onClick={() => logout()}>
          Logout
        </button>
      </div>
    )
  },
)

export default AvatarMenu
