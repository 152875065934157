import axios, {AxiosError} from 'axios'
import {CMS_HOST, CMS_TOKEN} from '@reditor/core/config'
import {
  GeneralFilters,
  SingleDataResponse,
  MultiDataResponse,
} from '@reditor/core/domains/data-request'

const apiAxiosInstance = axios.create({
  baseURL: `${CMS_HOST}`,
  headers: {
    'x-api-key': CMS_TOKEN,
  },
})

// mapping action to method
/** @type {Record<RequestAction, Method>} */
const actionMethodMap = Object.freeze({
  CREATE: 'POST',
  READ: 'GET',
  READ_MANY: 'GET',
  UPDATE: 'PUT',
  DELETE: 'DELETE',
  PATCH: 'PATCH',
})

/**
 * @param {((value: import("axios").InternalAxiosRequestConfig<any>) => import("axios").InternalAxiosRequestConfig<any> | Promise<import("axios").InternalAxiosRequestConfig<any>>) | null | undefined} interceptor
 */
export async function addInterceptor(interceptor) {
  apiAxiosInstance.interceptors.request.use(interceptor)
}

/**
 * @type {import('@reditor/core/gateways/data-request').DataRequestGatewayInterface["request"]}
 */
export async function request({path, action = 'READ', ...config}) {
  if (config.params) {
    // spread pagination
    if (config.params.pagination) {
      config.params.page = config.params.pagination.page
      config.params.pageSize = config.params.pagination.pageSize
      delete config.params.pagination
    }

    // spread filters
    if(config.params.filters) {
      Object.keys(config.params.filters).forEach((key) => {
        const filter = config.params.filters[key]
        if(Array.isArray(filter)) {
          config.params[key] = filter.join(',')
        } else  {
          config.params[key] = filter
        }
      })
      delete config.params.filters
    }
  }
  return apiAxiosInstance({
    url: path,
    method: actionMethodMap[action],
    ...config,
  })
    .then((response) => {
      return response.data
    })
    .catch((err) => {
      // console.log('catch err', err)
      throw err
    })
}

/**
 * @typedef {import('@reditor/core/gateways/data-request').RequestAction} RequestAction
 * @typedef {import('axios').Method} Method
 */
