import { cva } from "class-variance-authority"
import IMG_GIANT_GROUP from "images/brands/giant_group.png"
import React from "react"

/**
 * @typedef {import("class-variance-authority").VariantProps<typeof GiantLogoVariants>} GiantLogoVariants
 */
const GiantLogoVariants = cva("relative text-skyBlue-70 font-semibold", {
  variants: {
    size: {
      small: "h-[38px] text-xs",
      medium: "h-12 text-base",
    },
  },
  defaultVariants: {
    size: "medium",
  },
})

/**
 * @typedef {React.ImgHTMLAttributes<HTMLImageElement>} ImgProps
 */
/**
 * @type {React.FC<{
 *  className?: string
 *  imgClassName?: string
 *  siteEnv?: string
 * } & ImgProps & GiantLogoVariants>}
 */
const GiantLogo = ({
  className,
  siteEnv,
  imgClassName,
  alt = "giant",
  size,
  ...imgProps
}) => {
  return (
    <div className={GiantLogoVariants({ size, className })}>
      <img alt={alt} {...imgProps} className="h-full" src={IMG_GIANT_GROUP} />
      <span className="absolute bottom-0 left-1">{siteEnv}</span>
    </div>
  )
}
export default GiantLogo
